<template>
    <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="35%" :title="`${isEdit ? '编辑' : '添加'}绑定`" :visible.sync="isShow">
    <!-- <select-map :localChange='onCallbackMarker' ref='map'></select-map> -->
    <div class="all-input">
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>对象类型：</div>
        <el-select
          @change="clearID"
          style="width: 50%;!important"
          v-model="formData.type"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in objType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-show="formData.type" style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择对象：</div>
        <el-select
          style="width: 50%;!important"
          v-model="id"
          placeholder="请选择"
          v-show='formData.type !== "1"'
          filterable
        >
          <el-option
            v-for="item in formData.type === '0' ? pArr : dArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div style="width: 50%;!important" v-show="formData.type === '1'" class="map" @click="selectPe">{{nickname}}</div>

      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择设备：</div>
        <el-select
          style="width: 50%;!important"
          v-model="formData.u_id"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in uArr"
            :key="item.id"
            :label="item.unit_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <select-table ref="addP" :choice='onCallbackPe'></select-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getBSelect, addInd, updIndDetail } from '@/api/bind'
// import { getDailySelect } from '@/api/daily'
import selectTable from '@/components/selectTable'

export default {
  name: 'addInd',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectTable,
  },
  data () {
    return {
      isEdit: false,
      isShow: true,
      objType: [
        { id: '1', name: '人员' },
        { id: '0', name: '工程' },
        { id: '2', name: '深基坑' },
      ],
      formData: {
        type: '',
        d_id: '',
        p_id: '',
        bind_id: '',
        u_id: '',
      },
      id: '',
      uArr: [], // 设备下拉
      pArr: [], // 工程下拉
      dArr: [], // 坑下拉
      optionProps: {
        value: 'id',
        label: 'title',
        children: 'children',
      },
      options: [], // 人员
      list: [],
      ID: 16,
      VALUES: [],
      nickname: '',
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      this.nickname = this.data.nickname
      const { type, d_id: did, p_id: pid, bind_id: bid, u_id: uid, id } = this.data
      this.formData = { type: String(type), d_id: did, p_id: pid, bind_id: bid, u_id: uid, id }
      if (String(type) === '0') this.id = pid
      else if (String(type) === '2') this.id = did
    }
  },
  methods: {
    _loadSelect: function() {
      getBSelect().then(res => {
        const { result } = res
        this.uArr = result.unit
        this.pArr = result.project
        this.dArr = result.deeppit
      })

      // getDailySelect().then(res => {
      //   this.options = res.result
      //   // const list = JSON.parse(JSON.stringify(res.result))
      //   // this.deepData(this.options)
      // })
    },

    selectPe: function() {
      this.$refs.addP.open()
    },
    onCallbackPe: function(res) {
      this.formData.bind_id = res.id
      this.nickname = res.nickname
    },

    deepData: function(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length) this.deepData(list[i].children)
        else list[i].id = String(list[i].id)

        if (list[i].id === this.ID) {
          this.VALUES.push(list[i].id)
          this.ID = list[i].pid
          break
        }
      }
      console.log(this.VALUES, this.ID)
    },
    clearID: function() {
      this.id = ''
      this.formData.bind_id = ''
    },
    submit: function() {
      // console.log(this.list)
      if (!this.id && !this.formData.bind_id) {
        this.$toast('请选择 选择对象')
        return false
      } else if (!this.formData.type) {
        this.$toast('请选择 对象类型')
        return false
      } else if (!this.formData.u_id) {
        this.$toast('请选择 选择设备')
        return false
      }
      if (this.formData.type === '0') {
        this.formData.p_id = this.id
      } else if (this.formData.type === '2') {
        this.formData.d_id = this.id
      }
      // this.formData.type = this.formData.type

      this.isEdit ? this.edit() : this.save()
    },
    save: function() {
      addInd(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    edit: function() {
      updIndDetail(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    close: function() {
      this.isShow = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
