<template>
  <div >
      <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
      <TABS :fileChange='onCallbackFile' :tabChange='onCallbackTab' :list="tabsArr"></TABS>
      <TABLE  :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
      <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

      <!-- 添加 or 编辑设备 -->
      <add-ind  :data='bdata' @close='onCallbackClose' v-if="isShowAdd"></add-ind>

      <!-- 以下是子组件 -->
      <show-live ref='showLive'></show-live>
      <!--  -->
      <show-location @close="() => showType = ''" :id='this.showTypeMemID' :isSearch='true' v-if="showType === 'showLocation'"></show-location>
      <show-location @close="() => showType = ''" :id='this.showTypeMemID' :isSearch='false' v-if="showType === 'showLocationHd'"></show-location>
    <!--  -->
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getInd, getIndDetail, downloadBind, expIndBind, impIndBind, delIndBind } from '@/api/bind'
import showLive from '@/components/device/showLive' // 单兵播放
import showLocation from '@/components/device/showLocation' // 定位轨迹

import addInd from './components/addInd' // 添加 绑定
import { downloadExcel } from '@/utils/util'
export default {
  name: 'IndBind',
  components: {
    // selectPoint,
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    addInd,
    showLive,
    showLocation,
  },
  data() {
    return {
      searchArr: [
        {
          name: '设备名称',
          type: 'input',
          options: [],
          key: 'projectName',
        },
        {
          name: '设备编号',
          type: 'input',
          key: 'id',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加绑定',
          func: 'openAdd',
        },
        {
          type: 'primary',
          name: '导入数据',
          func: '',
          isLoading: false,
          isImport: true,
        },
        {
          type: 'primary',
          name: '下载模板',
          func: 'downloadTem',
          isLoading: false,
        },
        {
          type: 'primary',
          name: '导出数据',
          func: 'downloadExp',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeInd',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'object_name',
          label: '绑定对象',
          width: '150',
        },
        {
          prop: 'unit_name',
          label: '设备名称',
          width: '150',
        },
        {
          prop: 'unit_member',
          label: '设备编号',
          width: '180',
        },
        {
          prop: 'address.location',
          label: '地理位置',
          width: '180',
        },
        {
          prop: 'online',
          label: '在线状态',
          width: '120',
          type: 'tag',
        },
        {
          prop: 'status',
          label: '设备状态',
          width: '120',
          type: 'tag',
        },
        {
          prop: 'auth',
          label: '相关数据',
          type: 'other',
        },
        {
          prop: '',
          label: '操作',
          width: '170',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'edit',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeInd',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        unit_name: '',
        unit_member: '',
      },
      ids: '', // 要删除的id
      tabsIndex: 0, // tabs 按钮的索引
      other: [
        {
          type: '',
          name: '现场实景',
          func: 'open',
          component: 'showLive',
        },
        {
          type: '',
          name: '实时对讲',
          func: 'open',
          component: 'showLiveTalk',
        },
        {
          type: '',
          name: '定位轨迹',
          func: 'open',
          component: 'showLocation',
        },
      ], // 单兵
      showType: '', // 打开的子组件
      showTypeID: '', // id
      showTypeMemID: '', // 设备号
      isShowLog: false, // 人脸识别
      isShowAdd: false,
      bdata: {},
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getInd(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.unit_status = {
            status: item.unit_status === 1,
            disabled: false,
            func: 'changeStatus',
          }
          item.online = {
            type: item.online === 1 ? 'success' : 'danger',
            name: item.online === 1 ? '在线' : '离线',
          }
          item.status = {
            type: item.status === 1 ? 'success' : 'danger',
            name: item.status === 1 ? '显示' : '隐藏',
          }
          item.other = this.other
        })
        this.dataArr = result.data
      })
    },

    // 打开新增设备弹窗
    openAdd: function() {
      this.isShowAdd = true
    },
    edit: function() {
      getIndDetail(this.ids).then(res => {
        this.bdata = res.result
        this.isShowAdd = true
      })
    },

    downloadTem: function() {
      downloadBind().then(res => {
        const { result } = res
        downloadExcel({
          header: result.header,
          data: result.data,
          name: '绑定模板',
        })
        this.loading()
      })
    },

    downloadExp: function() {
      expIndBind().then(res => {
        const { result } = res
        result.data.map(s => {
          s.bind && Object.keys(s.bind).forEach(i => {
            s[i] = s.bind[i]
          })
          // eslint-disable-next-line no-unused-expressions
          !s.bind && (s.b_name = '', s.b_serial = '')
          return s
        })

        downloadExcel({
          header: result.header,
          data: result.data,
          name: '单兵绑定',
          title: ['type', 'd_name', 'b_serial', 'b_name', 'd_serial'],
        })
        this.loading()
      })
    },

    open: function(item, type) {
      if (type === 'showLive') {
        this.$refs.showLive.open({ name: item.unit_name, num: item.unit_member, isShowTalk: false })
      } else if (type === 'showLiveTalk') {
        this.$refs.showLive.open({ name: item.unit_name, num: item.unit_member, isShowTalk: true })
      } else {
        this.showTypeMemID = item.unit_member
        this.showTypeID = item.id
        this.showType = type
      }
    },

    // 上传文件回调
    onCallbackFile: function(file) {
      console.log(file, '执行收到')
      impIndBind(file).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this._loadData()
        }
      })
    },
    // 关闭统计、添加
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.isShowLog = false
      // this._loadData()
    },

    // 删除设备
    removeInd: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的单兵')
        return false
      }
      delIndBind(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    onCallbackClose: function(event) {
      this.isShowAdd = false
      this.loading()
      this.bdata = {}
      event && this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { id, projectName } = data
      this.formData.unit_member = id
      this.formData.unit_name = projectName
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func, type) {
      this.ids = item.id
      this[func](item, type)
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
